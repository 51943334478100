//Bold
@font-face
    font-family: 'ProximaNova'
    src: url("../fonts/proxima-nova/31FE2C_2_0.woff2") format("woff2"), url("../fonts/proxima-nova/31FE2C_2_0.woff") format("woff"), url("../fonts/proxima-nova/31FE2C_2_0.ttf") format("truetype")
    font-weight: bold
    font-style: normal

//Light
@font-face
    font-family: 'ProximaNova'
    src: url("../fonts/proxima-nova/31FE2C_5_0.woff2") format("woff2"), url("../fonts/proxima-nova/31FE2C_5_0.woff") format("woff"), url("../fonts/proxima-nova/31FE2C_5_0.ttf") format("truetype")
    font-weight: 100
    font-style: normal

//Regular
@font-face
    font-family: 'ProximaNova'
    src: url("../fonts/proxima-nova/31FE2C_8_0.woff2") format("woff2"), url("../fonts/proxima-nova/31FE2C_8_0.woff") format("woff"), url("../fonts/proxima-nova/31FE2C_8_0.ttf") format("truetype")
    font-weight: normal
    font-style: normal

//Semibold
@font-face
    font-family: 'ProximaNova'
    src: url("../fonts/proxima-nova/31FE2C_B_0.woff2") format("woff2"), url("../fonts/proxima-nova/31FE2C_B_0.woff") format("woff"), url("../fonts/proxima-nova/31FE2C_B_0.ttf") format("truetype")
    font-weight: 300
    font-style: normal

/* Unused fonts
    @font-face
        font-family: 'ProximaNovaA-Bold'
        src: url("../../fonts/proxima-nova/31FE2C_0_0.eot")
        src: url("../../fonts/proxima-nova/31FE2C_0_0.eot?#iefix") format("embedded-opentype"), url("../../fonts/proxima-nova/31FE2C_0_0.woff2") format("woff2"), url("../../fonts/proxima-nova/31FE2C_0_0.woff") format("woff"), url("../../fonts/proxima-nova/31FE2C_0_0.ttf") format("truetype")
    @font-face
        font-family: 'ProximaNovaA-Light'
        src: url("../../fonts/proxima-nova/31FE2C_3_0.eot")
        src: url("../../fonts/proxima-nova/31FE2C_3_0.eot?#iefix") format("embedded-opentype"), url("../../fonts/proxima-nova/31FE2C_3_0.woff2") format("woff2"), url("../../fonts/proxima-nova/31FE2C_3_0.woff") format("woff"), url("../../fonts/proxima-nova/31FE2C_3_0.ttf") format("truetype")
    @font-face
        font-family: 'ProximaNovaA-Regular'
        src: url("../../fonts/proxima-nova/31FE2C_6_0.eot")
        src: url("../../fonts/proxima-nova/31FE2C_6_0.eot?#iefix") format("embedded-opentype"), url("../../fonts/proxima-nova/31FE2C_6_0.woff2") format("woff2"), url("../../fonts/proxima-nova/31FE2C_6_0.woff") format("woff"), url("../../fonts/proxima-nova/31FE2C_6_0.ttf") format("truetype")
    @font-face
        font-family: 'ProximaNovaA-Semibold'
        src: url("../../fonts/proxima-nova/31FE2C_9_0.eot")
        src: url("../../fonts/proxima-nova/31FE2C_9_0.eot?#iefix") format("embedded-opentype"), url("../../fonts/proxima-nova/31FE2C_9_0.woff2") format("woff2"), url("../../fonts/proxima-nova/31FE2C_9_0.woff") format("woff"), url("../../fonts/proxima-nova/31FE2C_9_0.ttf") format("truetype")
    @font-face
        font-family: 'ProximaNovaS-Bold'
        src: url("../../fonts/proxima-nova/31FE2C_1_0.eot")
        src: url("../../fonts/proxima-nova/31FE2C_1_0.eot?#iefix") format("embedded-opentype"), url("../../fonts/proxima-nova/31FE2C_1_0.woff2") format("woff2"), url("../../fonts/proxima-nova/31FE2C_1_0.woff") format("woff"), url("../../fonts/proxima-nova/31FE2C_1_0.ttf") format("truetype")
    @font-face
        font-family: 'ProximaNovaS-Light'
        src: url("../../fonts/proxima-nova/31FE2C_4_0.eot")
        src: url("../../fonts/proxima-nova/31FE2C_4_0.eot?#iefix") format("embedded-opentype"), url("../../fonts/proxima-nova/31FE2C_4_0.woff2") format("woff2"), url("../../fonts/proxima-nova/31FE2C_4_0.woff") format("woff"), url("../../fonts/proxima-nova/31FE2C_4_0.ttf") format("truetype")
    @font-face
        font-family: 'ProximaNovaS-Regular'
        src: url("../../fonts/proxima-nova/31FE2C_7_0.eot")
        src: url("../../fonts/proxima-nova/31FE2C_7_0.eot?#iefix") format("embedded-opentype"), url("../../fonts/proxima-nova/31FE2C_7_0.woff2") format("woff2"), url("../../fonts/proxima-nova/31FE2C_7_0.woff") format("woff"), url("../../fonts/proxima-nova/31FE2C_7_0.ttf") format("truetype")
    @font-face
        font-family: 'ProximaNovaS-Semibold'
        src: url("../../fonts/proxima-nova/31FE2C_A_0.eot")
        src: url("../../fonts/proxima-nova/31FE2C_A_0.eot?#iefix") format("embedded-opentype"), url("../../fonts/proxima-nova/31FE2C_A_0.woff2") format("woff2"), url("../../fonts/proxima-nova/31FE2C_A_0.woff") format("woff"), url("../../fonts/proxima-nova/31FE2C_A_0.ttf") format("truetype")
